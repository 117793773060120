import React from "react"
import ArticleContentBody from "../../components/pages/ArticleDetails/ArticleContentBody"
import Layout from "../../components/layout"
import Seo from "../../components/seo"

const UserFilesFolder = () => {
  return (
    <Layout>
      <Seo
        title="User Files Folder"
        description={`Manage your personal files efficiently with Synkli's User Files Folder. Learn to view, download, share, and delete files seamlessly with our user-friendly interface.`}
      />

      {/* This slug is matched with articles in Content */}
      <ArticleContentBody slug={`user-files-folder`} />
    </Layout>
  )
}

export default UserFilesFolder
